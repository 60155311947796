import api from 'helpers/api';
import { ClientUser, ClientUserUpdateInformation } from 'types/client-user';
import { ClientUserUpdateBody } from 'v2.api/src/client-users/dto/client-user-update-body.dto';
import { toQueryString } from 'v2.api/src/common-generic/query-string';
import { ClientUserEntity } from 'v2.api/src/entities/client-user.entity';
import { CompanyEntity } from 'v2.api/src/entities/company.entity';
import { ContactEntity } from 'v2.api/src/entities/contact.entity';

export const getClientUserForOnboarding = async (companyId: string) => {
  const { data } = await api<{
    onboarding_status: string;
    firstname: string;
    lastname: string;
  }>({
    method: 'get',
    origin: process.env.NEXT_PUBLIC_V2_API_URL,
    url: `/v2/companies/${companyId}/client-user-onboarding`,
  });

  return data;
};

export const getClientUser = async (query: {
  email: string;
  withCommonRole?: boolean;
}) => {
  const {
    data: [clientUser],
  } = await api<Array<ClientUserEntity>>({
    method: 'get',
    origin: process.env.NEXT_PUBLIC_V2_API_URL,
    url: `/v2/client-users${toQueryString(query)}`,
  });

  return clientUser;
};

export const updateClientUserInformation = async (
  clientUserInformation: ClientUserUpdateBody,
  clientUserId: string,
) => {
  const { data } = await api<ClientUser>({
    method: 'put',
    url: `/v2/client-users/${clientUserId}`,
    origin: process.env.NEXT_PUBLIC_V2_API_URL,
    body: clientUserInformation,
  });

  return data;
};

export const onboardClientUser = async (
  clientUserInformation: ClientUserUpdateInformation,
  companyId: string,
  hasAccessToAllActions: boolean,
) => {
  const { data } = await api<ClientUserEntity>({
    method: 'put',
    url: '/v2/client-users/onboarding',
    origin: process.env.NEXT_PUBLIC_V2_API_URL,
    body: {
      ...clientUserInformation,
      companyId,
      hasAccessToAllActions,
    },
    isAuthenticated: false,
  });

  return data;
};

export const inviteClientUser = async (
  email: string,
  companyId: string,
  checkedRole: string,
  firstname?: string,
  lastname?: string,
) => {
  const { data } = await api<ClientUser>({
    method: 'post',
    url: `/v2/companies/${companyId}/client-users`,
    origin: process.env.NEXT_PUBLIC_V2_API_URL,
    body: {
      email,
      firstname,
      lastname,
      checkedRole,
    },
  });

  return data;
};

export const getClientUserCompanies = async (userId: string) => {
  const {
    data: { companies },
  } = await api<{ companies: Array<CompanyEntity> }>({
    method: 'get',
    url: `/v2/client-users/${userId}/companies`,
    origin: process.env.NEXT_PUBLIC_V2_API_URL,
  });

  return companies;
};

export const getOnboardedClientUserCompanies = async (userId: string) => {
  const {
    data: { companies },
  } = await api<{ companies: Array<CompanyEntity> }>({
    method: 'get',
    url: `/v2/client-users/${userId}/companies/onboarding-done`,
    origin: process.env.NEXT_PUBLIC_V2_API_URL,
  });

  return companies;
};

export const getClientUsersForCompany = async (companyId: string) => {
  const { data } = await api<Array<ContactEntity>>({
    method: 'get',
    url: `/v2/companies/${companyId}/client-users`,
    origin: process.env.NEXT_PUBLIC_V2_API_URL,
  });

  return data;
};

export const deleteClientUser = async (
  companyId: string,
  clientUserId: string,
): Promise<void> => {
  await api({
    method: 'delete',
    url: `/v2/companies/${companyId}/client-users/${clientUserId}`,
    origin: process.env.NEXT_PUBLIC_V2_API_URL,
  });
};

export const revokeClientUserInvitation = async (
  companyId: string,
  clientUserId: string,
): Promise<void> => {
  await api({
    method: 'delete',
    origin: process.env.NEXT_PUBLIC_V2_API_URL,
    url: `/v2/companies/${companyId}/client-users/${clientUserId}/invitation`,
  });
};

export const updateOnboardingEmail = async (
  oldEmail: string,
  newEmail: string,
) => {
  await api<ClientUserEntity>({
    method: 'put',
    url: '/v2/client-users/onboarding-email',
    origin: process.env.NEXT_PUBLIC_V2_API_URL,
    body: {
      oldEmail,
      newEmail,
    },
  });
};

import Cookies from 'js-cookie';

export const logOut = (): void => {
  Cookies.remove('access_token');
  Cookies.remove('admin_access_token');
  Cookies.remove('role');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('redirect_url');
};

export const clientUserLogOut = (): void => {
  if (!hasAdminAccessToken()) {
    Cookies.remove('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('redirect_url');
  }
};

export const hasAdminAccessToken = (): boolean => {
  return Cookies.get('admin_access_token');
};

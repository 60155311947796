import * as Sentry from '@sentry/react';
import { getEmailFromToken } from 'helpers/email';
import { tryUpdate } from 'helpers/intercom';
import { logOut } from 'helpers/log-out';
import { getUserFromToken } from 'helpers/token';
import Cookies from 'js-cookie';
import includes from 'lodash/includes';
import { useRouter } from 'next/router';
import bolder from 'observables/bolder';
import clientUser from 'observables/client-user';
import { getBolderByEmail } from 'queries/bolders';
import { getClientUser } from 'queries/client-users';
import { useEffect, useState } from 'react';
import { useIntercom } from 'react-use-intercom';
import { isBolder, isClientUser } from 'types/roles';
import { addDays, isBefore } from 'v2.api/src/common-generic';

interface Props {
  children: React.ReactNode;
}

const loginRedirectionWhitelist = [
  '/google-auth',
  '/new-password',
  '/client/onboarding',
  '/admin/login',
  '/share/[[...share_sub_route]]',
];

const AuthHandler = ({ children }: Props): React.ReactNode => {
  const [isAuthDone, setIsAuthDone] = useState(false);
  const [isObservableReady, setIsObservableReady] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const { push, replace, pathname } = useRouter();
  const { update } = useIntercom();

  const handleRedirection = () => {
    if (
      !pathname.endsWith('/login') &&
      !loginRedirectionWhitelist.includes(pathname)
    ) {
      push('/login');
    }
  };

  useEffect(() => {
    const refreshToken = localStorage.getItem('refresh_token');

    if (!refreshToken && Cookies.get('access_token')) {
      setIsObservableReady(true);
      setIsAuthDone(true);
      push('/login');
      logOut();
      return;
    }

    const initUser = async () => {
      const { email } = getUserFromToken();

      if (isBolder() && email && !bolder.value) {
        const user = await getBolderByEmail(email);
        bolder.setValue(user);

        if (
          !Cookies.get('admin_access_token') &&
          user.leaving_date &&
          isBefore(addDays(new Date(user.leaving_date), 1), new Date())
        ) {
          bolder.setValue(null);
          logOut();
          push('/login');
        }

        setIsObservableReady(true);

        return;
      }

      if (isClientUser() && email && !clientUser.value) {
        const user = await getClientUser({ email, withCommonRole: true });
        clientUser.setValue(user);
        setIsObservableReady(true);

        return;
      }

      if (bolder.value || clientUser.value) return;

      setIsAdmin(true);
    };

    if (!loginRedirectionWhitelist.includes(pathname)) {
      initUser();
    } else {
      setIsLogin(true);
    }
  }, [pathname, push]);

  useEffect(() => {
    const token = Cookies.get('access_token');
    const hmac = Cookies.get('hmac');
    const userEmail = Cookies.get('user_email');
    const role = Cookies.get('role');
    const refreshToken = localStorage.getItem('refresh_token');

    const emailFromToken = getEmailFromToken(token);

    const email = userEmail || emailFromToken;

    Sentry.setUser({ email });

    if (!refreshToken && token) {
      setIsObservableReady(true);
      setIsAuthDone(true);
      push('/login');
      logOut();
      return;
    }

    if (!token) {
      handleRedirection();
      setIsAuthDone(true);
      return;
    }

    if (
      includes(
        ['/login', '/admin/login', '/bolder/login', '/slack-auth', '/_error'],
        pathname,
      )
    ) {
      setIsAuthDone(true);
      return;
    }

    tryUpdate(update, { userHash: hmac, email });

    if (role === 'admin') {
      if (pathname.startsWith('/bolder') || pathname.startsWith('/client')) {
        push('/login');
      }

      if (pathname === '/') replace('/admin/hashtags');
      setIsAuthDone(true);
      return;
    }

    if (role === 'bolder') {
      if (pathname.startsWith('/admin') || pathname.startsWith('/client')) {
        push('/login');
      }

      if (pathname === '/') replace('/bolder/clients');
      setIsAuthDone(true);
      return;
    }

    if (role !== 'client_user') {
      push('/login');
      return;
    }

    if (
      pathname.startsWith('/admin') ||
      (pathname.startsWith('/bolder') &&
        !pathname.startsWith('/bolder/actions'))
    ) {
      push('/login');
    }

    if (pathname === '/') replace('/client');
    setIsAuthDone(true);
  }, [pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  if (isLogin) return children;

  if (!isAuthDone || (!isObservableReady && !isAdmin)) return null;

  return children;
};

export default AuthHandler;
